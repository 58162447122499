<template>
  <b-modal size="xl" ref="modal" :hide-footer="!loadingComplete" :hide-header="!loadingComplete">
    <template #modal-title>
      {{ activityInfo.title }}
      <span :class="calculatedStage">
        {{ $t('SYSTEM.ACTIVITY_STAGE.' + activityInfo.activity_stage.name.toUpperCase()) }}
      </span>
    </template>
    <template #modal-footer>
      <div class="d-flex justify-content-end">
        <button @click="$refs.modal.hide()" class="btn btn-outline-primary mr-3">
          Cancel
        </button>
        <router-link
          v-if="currentUser.menu_items.includes('AddEntity') && !legacy"
          tag="a"
          :to="{ name: calculatedEditRoute, params: { activity: activityId }}"
          class="btn btn-primary"
        >
          Edit Activity
        </router-link>
      </div>
    </template>
    <b-spinner
      v-if="loadingComplete == false"
      variant="primary"
      label="Spinning"
      class="card-loader"
    >
    </b-spinner>
    <div v-if="loadingComplete">
      <div class="d-flex justify-content-between">
        <div class="col-7 pl-5 pr-10" style="border-right: 1px solid #ebf3eb">
          <!--start::Activity Type-->
          <div class="d-flex justify-content-start py-1">
            <p class="font-weight-bold mb-0">Activity Type:</p>
            <p class="ml-2 mb-0">{{ activityInfo.activity_type.name }}</p>
          </div>
          <!--end::Activity Type-->
          <!--start::Activity Room-->
          <div class="d-flex justify-content-start py-1">
            <p class="font-weight-bold mb-0">Project Room:</p>
            <p class="ml-2 mb-8">{{ calculatedProjectRoom }}</p>
          </div>
          <!--start::Activity Room-->
          <!--start::Activity Dates-->
          <div class="py-2">
            <p class="mb-2 mt-3 font-weight-bold mt-3">Activity Dates:</p>
            <div class="d-flex justify-content-start mb-1">
              <p class="mb-0">{{ calculatedStartPrefix }}</p>
              <GenericDatetimeDisplay
                class="ml-2"
                :long="true"
                :date="activityInfo.starts_on"
                :lastUpdated="false"
                :relative="false"
              >
              </GenericDatetimeDisplay>
            </div>
            <div class="d-flex justify-content-start mb-10">
              <p class="mb-0">{{ calculatedEndPrefix }}</p>
              <GenericDatetimeDisplay
                class="ml-2"
                :long="true"
                :date="activityInfo.ends_on"
                :lastUpdated="false"
                :relative="false"
              >
              </GenericDatetimeDisplay>
            </div>
          </div>
          <!--end::Activity Dates-->
          <!--start::Activity Summary-->
          <p v-if="showSummary" class="mb-2 mt-3 font-weight-bold">Activity Summary:</p>
          <p v-if="showSummary" class="mb-10"><span v-html="activityInfo.summary"></span></p>
          <!--end::Activity Summary-->
          <!--start::Activity Introduction-->
          <p v-if="showIntroduction" class="mb-2 mt-3 font-weight-bold">Activity Introduction:</p>
          <p v-if="showIntroduction" class="mb-10"><span v-html="activityInfo.introduction"></span></p>
          <!--end::Activity Introduction-->
        </div>
        <div class="col-5 pl-10 pr-5">
          <p class="font-weight-bold mb-0">Allocation</p>
          <p class="mb-3">{{ calculatedAllocationText }}</p>
          <ul class="px-10" v-if="activityInfo.project_segments.length > 0">
            <template v-for="(segment, i) in activityInfo.project_segments">
              <li :key="i">{{ segment.name }}</li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { mapState } from "vuex";

import { POPULATE_ACTIVITY_INFO, POPULATE_LEGACY_ACTIVITY_INFO } from "@/core/services/store/activity/activity.module";

import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay"

import LoadingCompleteHelper from "@/helpers/LoadingCompleteHelper";

export default {
  name: 'ActivityDetails',

  props: {
    activityId: { type: Number, required: false }
  },

  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 1
      },
      error: {
        active: false,
        message: ""
      },
    }
  },

  components: {
    GenericDatetimeDisplay
  },

  methods: {
    fetchActivityInfo: function(val) {
      let payload = {
        projectId: this.projectInfo.id,
        activityId: val, 
        params: {
          activityType: 'with',
          activityStage: 'with',
          projectRoom: 'with',
          projectSegments: 'with'
        }
      };

      this.$store
        .dispatch(POPULATE_ACTIVITY_INFO, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message = 
            "We're having some issues retrieving the activity information, please check back later or contact the helpdesk";
        });
    },
    fetchLegacyActivityInfo: function(activityId) {
      let payload = {
        projectId: this.projectInfo.id,
        activityId: activityId
      };
      this.$store.dispatch(POPULATE_LEGACY_ACTIVITY_INFO, payload)
      .then(() => {
        this.pageLoader.componentsCompleted++;  
      })
      .catch(() => {
        this.error.active = true;
        this.error.message = 
          "We're having some issues retrieving the legacy activity information, please check back later or contact the helpdesk";
      });
    }
  },

  watch: {
    activityId: {
      handler(val) {
        if (val != null) {
          this.pageLoader.componentsCompleted = 0;
          if (this.legacy) {
            this.fetchLegacyActivityInfo(val);
          } else {
            this.fetchActivityInfo(val);
          }
        }
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters([
      "projectInfo",
      "activityInfo",
      "currentUser"
    ]),
    ...mapState({
      legacy: state => state.projectStream.legacy
    }),
    loadingComplete: function() {
      return LoadingCompleteHelper.loadingComplete(this.pageLoader, this.error);
    },
    showSummary: function() {
      return this.activityInfo.summary != null ? true : false;
    },
    showIntroduction: function() {
      return this.activityInfo.introduction != null ? true : false;
    },
    calculatedProjectRoom: function() {
      return (this.activityInfo.project_room_id == null) ? "No Assigned Room" : this.activityInfo.project_room.title;
    },
    calculatedStartPrefix: function() {
      let activityStageId = this.activityInfo.system_activity_stage_id;

      if (activityStageId == 1 || activityStageId == 2) {
        return "Starting On:";
      } else {
        return "Started On:";
      }
    },
    calculatedEndPrefix: function() {
      let activityStageId = this.activityInfo.system_activity_stage_id;

      if (activityStageId == 4) {
        return "Ended On:";
      } else {
        return "Ending On:";
      }
    },
    calculatedAllocationText: function() {
      if (this.activityInfo.project_segments.length == 0) {
        return "This activity is available to all participants";
      } else {
        return "This activity is available to users in the below segments";
      }
    },
    calculatedStage: function() {
      let calculatedStage = null;

      switch (this.activityInfo.system_activity_stage_id) {
        case 1:
          calculatedStage = "label label-light-warning label-pill label-inline ml-1";
          break;
        case 2:
          calculatedStage = "label label-light-info label-pill label-inline ml-1";
          break;
        case 3:
          calculatedStage = "label label-light-success label-pill label-inline ml-1";
          break;
        case 4:
          calculatedStage = "label label-light-danger label-pill label-inline ml-1";
          break;
      }

      return calculatedStage;
    },
    calculatedEditRoute: function() {
      return "edit_" + this.activityInfo.activity_type.name.toLowerCase().split(' ').join('_');
    }
  }
};
</script>
